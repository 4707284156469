import React from 'react';
import { makeStyles, Typography } from '@material-ui/core/';
import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px 20px',
    paddingLeft: '30px',
    backgroundColor: '#FAFAFA',
    [theme.breakpoints.up('lg')]: {
      margin: '60px 200px',
    },
    '&:last-child': {
      paddingBottom: '40px',
    },
  },
  heading: {
    paddingTop: '68px',
    color: theme.palette.coreDarkBlue,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    fontSize: '32px',
    marginBottom: '51px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '36px',
    },
  },
  secHeading: {
    marginTop: '71px',
    marginBottom: '50px',
    fontSize: '24px',
  },
  link: {
    color: theme.palette.coreRed,
    display: 'block',
    marginBottom: '20px',
    fontFamily: 'Open Sans',
    fontSize: '24px',
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={classes.container}>
        <Typography variant="h1" className={classes.heading}>
          Sorry, we could not find that page
        </Typography>
        <Typography variant="h3" className={classes.secHeading}>
          The page may have been moved or deleted.
        </Typography>
        <a href="/" className={classes.link}>
          Go to the homepage
        </a>
        <a href="/search" className={classes.link}>
          Start a new search
        </a>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
